import {
  ERROR_RECEIVE_CLIENTS_AND_PROJECTS,
  ERROR_REFRESH_PROJECTS,
  RECEIVE_CLIENTS_AND_PROJECTS,
  REQUEST_CLIENTS_AND_PROJECTS,
  REQUEST_REFRESH_PROJECTS,
  SUCCESS_REFRESH_PROJECTS,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const errorReceiveClientsAndProjects = () => ({
  type: ERROR_RECEIVE_CLIENTS_AND_PROJECTS,
});

const receiveClientsAndProjects = (clientsAndProjects: any) => ({
  payload: clientsAndProjects,
  type: RECEIVE_CLIENTS_AND_PROJECTS,
});

const requestClientsAndProjects = () => ({
  type: REQUEST_CLIENTS_AND_PROJECTS,
});

const getClientsAndProjects = () => {
  const {
    BASE_URL,
    PROJECT_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${PROJECT_SERVICE}`)
    .then(({ data }) => data);
};

const fetchClientsAndProjects = () => (dispatch: any) => {
  dispatch(requestClientsAndProjects());
  return getClientsAndProjects()
    .then(clients => dispatch(receiveClientsAndProjects(clients)))
    .catch(() => dispatch(errorReceiveClientsAndProjects()));
};

const requestRefreshClientsAndProjects = () => ({
  type: REQUEST_REFRESH_PROJECTS,
});

const successRefreshClientsAndProjects = (clientsAndProjects: any) => ({
  payload: clientsAndProjects,
  type: SUCCESS_REFRESH_PROJECTS,
});

const errorRefreshClientsAndProjects = () => ({
  type: ERROR_REFRESH_PROJECTS,
});

const refreshClientsAndProjects = () => {
  const {
    BASE_URL,
    PROJECT_SERVICE,
  } = config;

  return axios.post(`${BASE_URL}${PROJECT_SERVICE}/refresh`);
};

const fetchRefreshClientsAndProjects = () => (dispatch: any) => {
  dispatch(requestRefreshClientsAndProjects());
  return refreshClientsAndProjects()
    .then(() => (
      getClientsAndProjects()
        .then(clients => dispatch(successRefreshClientsAndProjects(clients)))
        .catch(() => dispatch(errorReceiveClientsAndProjects()))
    ))
    .catch(() => dispatch(errorRefreshClientsAndProjects()));
};

const exportFunctions = {
  fetchClientsAndProjects,
  fetchRefreshClientsAndProjects,
};

export default exportFunctions;
