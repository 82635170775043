// eslint-disable-next-line max-len
export const ERROR_RECEIVE_CLIENTS_AND_PROJECTS = 'ERROR_RECEIVE_CLIENTS_AND_PROJECTS';
export const RECEIVE_CLIENTS_AND_PROJECTS = 'RECEIVE_CLIENTS_AND_PROJECTS';
export const REQUEST_CLIENTS_AND_PROJECTS = 'REQUEST_CLIENTS_AND_PROJECTS';

export const REQUEST_REFRESH_PROJECTS = 'REQUEST_REFRESH_PROJECTS';
export const SUCCESS_REFRESH_PROJECTS = 'SUCCESS_REFRESH_PROJECTS';
export const ERROR_REFRESH_PROJECTS = 'ERROR_REFRESH_PROJECTS';

export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';
