import {
  ERROR_CREATE_CONTRACT_TYPE,
  ERROR_DELETE_CONTRACT_TYPE,
  ERROR_RECEIVE_CONTRACT_TYPES,
  ERROR_UPDATE_CONTRACT_TYPE,
  RECEIVE_CONTRACT_TYPES,
  REQUEST_CREATE_CONTRACT_TYPE,
  REQUEST_DELETE_CONTRACT_TYPE,
  REQUEST_CONTRACT_TYPES,
  REQUEST_UPDATE_CONTRACT_TYPE,
  SUCCESS_CREATE_CONTRACT_TYPE,
  SUCCESS_DELETE_CONTRACT_TYPE,
  SUCCESS_UPDATE_CONTRACT_TYPE,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const requestTypes = () => ({
  type: REQUEST_CONTRACT_TYPES,
});

const receiveTypes = (response: any) => ({
  payload: response,
  type: RECEIVE_CONTRACT_TYPES,
});

const errorReceiveTypes = () => ({
  type: ERROR_RECEIVE_CONTRACT_TYPES,
});

const getTypes = (activeOnly = false) => {
  const {
    BASE_URL,
    CONTRACT_TYPE_SERVICE,
  } = config;

  return axios
    .get(
      `${BASE_URL}${CONTRACT_TYPE_SERVICE}`,
      { params: { activeOnly } }
    )
    .then(({ data }) => data);
};

const fetchTypes = () => (dispatch: any) => {
  dispatch(requestTypes());

  return getTypes()
    .then(types => dispatch(receiveTypes(types)))
    .catch(() => dispatch(errorReceiveTypes()));
};

const requestCreateType = () => ({
  type: REQUEST_CREATE_CONTRACT_TYPE,
});

const successCreateType = (response: any) => ({
  payload: response,
  type: SUCCESS_CREATE_CONTRACT_TYPE,
});

const errorCreateType = (response: any) => ({
  payload: response,
  type: ERROR_CREATE_CONTRACT_TYPE,
});

const createType = ({
  active,
  bankDetailsType,
  includeInPolishPaymentRegistry,
  invoiceFormCode,
  isRoundPayrollSum,
  name,
  payRegistryFormCode,
  shouldSendInvoiceInEmail,
  showSumInUahInEmail,
  taxTypeIdsPaidByCompany,
  taxTypeIdsCompensatedByCompany,
}: any) => {
  const {
    BASE_URL,
    CONTRACT_TYPE_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${CONTRACT_TYPE_SERVICE}`, {
      active,
      bankDetailsType,
      includeInPolishPaymentRegistry,
      invoiceFormCode,
      isRoundPayrollSum,
      name,
      payRegistryFormCode,
      shouldSendInvoiceInEmail,
      showSumInUahInEmail,
      taxTypeIdsCompensatedByCompany,
      taxTypeIdsPaidByCompany,
    });
};

const fetchCreateType = ({
  active,
  bankDetailsType,
  includeInPolishPaymentRegistry,
  invoiceFormCode,
  isRoundPayrollSum,
  name,
  payRegistryFormCode,
  shouldSendInvoiceInEmail,
  showSumInUahInEmail,
  taxTypeIdsPaidByCompany,
  taxTypeIdsCompensatedByCompany,
}: any) => (dispatch: any) => {
  dispatch(requestCreateType());

  return createType({
    active,
    bankDetailsType,
    includeInPolishPaymentRegistry,
    invoiceFormCode,
    isRoundPayrollSum,
    name,
    payRegistryFormCode,
    shouldSendInvoiceInEmail,
    showSumInUahInEmail,
    taxTypeIdsCompensatedByCompany,
    taxTypeIdsPaidByCompany,
  })
    .then(() => (
      getTypes()
        .then((types) => dispatch(successCreateType(types)))
        .catch(() => dispatch(errorReceiveTypes()))
    ))
    .catch((error) => dispatch(errorCreateType(error)));
};

const requestDeleteType = () => ({
  type: REQUEST_DELETE_CONTRACT_TYPE,
});

const successDeleteType = (response: any) => ({
  payload: response,
  type: SUCCESS_DELETE_CONTRACT_TYPE,
});

const errorDeleteType = (response: any) => ({
  payload: response,
  type: ERROR_DELETE_CONTRACT_TYPE,
});

const deleteType = ({ typeId }: any) => {
  const {
    BASE_URL,
    CONTRACT_TYPE_SERVICE,
  } = config;

  return axios
    .delete(`${BASE_URL}${CONTRACT_TYPE_SERVICE}/${typeId}`);
};

const fetchDeleteType = ({
  typeId,
}: any) => (dispatch: any) => {
  dispatch(requestDeleteType());

  return deleteType({ typeId })
    .then(() => (
      getTypes()
        .then((types) => dispatch(successDeleteType(types)))
        .catch(() => dispatch(errorReceiveTypes()))
    ))
    .catch((error) => dispatch(errorDeleteType(error)));
};

const requestUpdateType = () => ({
  type: REQUEST_UPDATE_CONTRACT_TYPE,
});

const successUpdateType = (response: any) => ({
  payload: response,
  type: SUCCESS_UPDATE_CONTRACT_TYPE,
});

const errorUpdateType = (response: any) => ({
  payload: response,
  type: ERROR_UPDATE_CONTRACT_TYPE,
});

const updateType = ({
  active,
  bankDetailsType,
  includeInPolishPaymentRegistry,
  invoiceFormCode,
  isRoundPayrollSum,
  name,
  payRegistryFormCode,
  shouldSendInvoiceInEmail,
  showSumInUahInEmail,
  taxTypeIdsPaidByCompany,
  taxTypeIdsCompensatedByCompany,
  typeId,
}: any) => {
  const {
    BASE_URL,
    CONTRACT_TYPE_SERVICE,
  } = config;

  return axios
    .put(`${BASE_URL}${CONTRACT_TYPE_SERVICE}/${typeId}`, {
      active,
      bankDetailsType,
      includeInPolishPaymentRegistry,
      invoiceFormCode,
      isRoundPayrollSum,
      name,
      payRegistryFormCode,
      shouldSendInvoiceInEmail,
      showSumInUahInEmail,
      taxTypeIdsCompensatedByCompany,
      taxTypeIdsPaidByCompany,
    });
};

const fetchUpdateType = ({
  active,
  bankDetailsType,
  includeInPolishPaymentRegistry,
  invoiceFormCode,
  isRoundPayrollSum,
  name,
  payRegistryFormCode,
  shouldSendInvoiceInEmail,
  showSumInUahInEmail,
  taxTypeIdsPaidByCompany,
  taxTypeIdsCompensatedByCompany,
  typeId,
}: any) => (dispatch: any) => {
  dispatch(requestUpdateType());
  return updateType({
    active,
    bankDetailsType,
    includeInPolishPaymentRegistry,
    invoiceFormCode,
    isRoundPayrollSum,
    name,
    payRegistryFormCode,
    shouldSendInvoiceInEmail,
    showSumInUahInEmail,
    taxTypeIdsCompensatedByCompany,
    taxTypeIdsPaidByCompany,
    typeId,
  })
    .then(() => (
      getTypes()
        .then((types) => dispatch(successUpdateType(types)))
        .catch(() => dispatch(errorReceiveTypes()))
    ))
    .catch((error) => dispatch(errorUpdateType(error)));
};

const exportFunctions = {
  fetchCreateType,
  fetchDeleteType,
  fetchTypes,
  fetchUpdateType,
};

export default exportFunctions;
