import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardTitle from 'components/CardTitle';
import classNames from 'classnames';
import Dialog from 'components/Dialog';
import Divider from 'components/Divider';
import IconButton from 'components/IconButton';
import IconClose from 'components/icons/Close';
import IconDelete from 'components/icons/Delete';
import IconEdit from 'components/icons/Edit';
import IconError from 'components/icons/Error';
import IconPlus from 'components/icons/Plus';
import Link from 'components/Link';
import Loading from 'components/Loading';
import MenuItem from 'components/MenuItem';
import moment from 'moment';
import Select from 'components/Select';
import Snackbar from 'components/Snackbar';
import Tab from 'components/Tab';
import Table from 'components/Table';
import TableBody from 'components/TableBody';
import TableCell from 'components/TableCell';
import TableCellActions from 'components/TableCellActions';
import TableCellHead from 'components/TableCellHead';
import TableHead from 'components/TableHead';
import TableRow from 'components/TableRow';
import Tabs from 'components/Tabs';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useAccessValidate from 'hooks/useAccessValidate';
import useChangePage from 'hooks/useChangePage';
import useIsMobile from 'hooks/useIsMobile';
import useLocationSearch from 'hooks/useLocationSearch';
import useTheme from 'hooks/useTheme';

import * as pages from 'constants/pages';
import { locales } from 'constants/languages';
import actionsCompensations from '../actions/compensations';
import actionsContractor from '../actions/contractor';
import actionsTypes from '../actions/compensationTypes';
import actionsContractTypes from '../actions/contractTypes';
import actionsSetup from '../actions/setup';
import authoritiesUI from 'constants/authoritiesUI';
import currenciesList, * as currencies from '../constants/currencies';
import dynamicInsertsList, * as dynamicInsertTypes
  from '../constants/dynamicInsertTypes';
import pagesURLs from 'constants/pagesURLs';
import rateMeasureTypesList, * as rateMeasureTypes
  from '../constants/rateMeasureTypes';
import contractorStatuses, * as statuses from '../constants/contractorStatus';
import * as sources from '../constants/contractorSource';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  bar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cell: {
    minWidth: '40%',
    width: '40%',
  },
  clickable: {
    cursor: 'pointer',
  },
  comment: {
    width: '340px',
  },
  commentHintContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(0.5)}px`,
  },
  compensationSum: {
    width: '110px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  contractorContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
    padding: `${theme.spacing(2)}px 0px`,
  },
  contractorMobileRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
  },
  currency: {
    width: '100px',
  },
  dash: {
    display: 'flex',
    flex: 0,
  },
  dialogCell: {
    minWidth: '25%',
    width: '25%',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  dialogContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(3.5)}px`,
  },
  dialogPeriodicity: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    width: '100%',
  },
  dialogSum: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
  },
  dynamicInsert: {
    minWidth: '130px',
  },
  dynamicInsertHintMobile: {
    display: 'flex',
    flex: 2,
  },
  dynamicInsertMobile: {
    display: 'flex',
    flex: 1,
    minWidth: '110px',
  },
  dynamicInsertRow: {
    display: 'flex',
    gap: `${theme.spacing(0.5)}px`,
  },
  dynamicInsertRowMobile: {
    display: 'flex',
    gap: `${theme.spacing(0.5)}px`,
    maxWidth: '300px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  infoContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2.5)}px`,
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
    minHeight: '20px',
  },
  rowCaptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(0.5)}px`,
  },
  rowMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(0.5)}px`,
    padding: `0px ${theme.spacing(2)}px`,
  },
  select: {
    width: '340px',
  },
  selectPeriodicity: {
    width: '40%',
  },
  selectPeriodicityMobile: {
    width: '65%',
  },
  selectYear: {
    width: '20%',
  },
  selectYearMobile: {
    width: '35%',
  },
}));

const errorTypes = {
  EMPTY_FIELD_EMAIL: 'EMPTY_FIELD_EMAIL',
  EMPTY_FIELD_FULL_NAME: 'EMPTY_FIELD_FULL_NAME',
  INVALID_INSERT_COUNT: 'INVALID_INSERT_COUNT',
  INVALID_INSERT_NUMBER: 'INVALID_INSERT_NUMBER',
};

const insertsToErrorTypes: { [key: string]: string } = {
  [dynamicInsertTypes.PAYMENT_COUNT]: errorTypes.INVALID_INSERT_COUNT,
  [dynamicInsertTypes.PAYMENT_NUMBER]: errorTypes.INVALID_INSERT_NUMBER,
};

const tabs = {
  COMPENSATIONS: 'COMPENSATIONS',
  DETAILS: 'DETAILS',
};

const authoritiesToTabs = {
  [tabs.DETAILS]: [authoritiesUI.CONTRACTORS],
  [tabs.COMPENSATIONS]: [authoritiesUI.COMPENSATIONS],
};

const TABLE_COLUMNS = {
  actions: 'ACTIONS',
  comment: 'COMMENT',
  periodicity: 'PERIODICITY',
  sum: 'SUM',
  type: 'TYPE',
};

const contractorInfoFields: { [key: string]: string } = {
  ADDRESS: 'ADDRESS',
  ADDRESS_POL: 'ADDRESS_POL',
  BANK_ADDRESS: 'BANK_ADDRESS',
  BANK_UKR_FO: 'BANK_UKR_FO',
  BANK_UKR_FOP: 'BANK_UKR_FOP',
  BANK_USD: 'BANK_USD',
  CONTRACT_TYPE: 'CONTRACT_TYPE',
  CORRESPONDENT_BANK: 'CORRESPONDENT_BANK',
  CORRESPONDENT_BANK_SWIFT: 'CORRESPONDENT_BANK_SWIFT',
  DOCUMENTS_LINK: 'DOCUMENTS_LINK',
  EMAIL: 'EMAIL',
  EMAIL_PERSONAL: 'EMAIL_PERSONAL',
  FULL_NAME: 'FULL_NAME',
  FULL_NAME_UKR: 'FULL_NAME_UKR',
  IBAN_FO: 'IBAN_FO',
  IBAN_FOP: 'IBAN_FOP',
  IBAN_USD: 'IBAN_USD',
  INN: 'INN',
  MFO: 'MFO',
  PHONE: 'PHONE',
  SOURCE: 'SOURCE',
  STATUS: 'STATUS',
  SWIFT: 'SWIFT',
  TAX_GROUP: 'TAX_GROUP',
};

const DEFAULT_COMPENSATION_TYPE = 'RATE';

const DATE_FORMAT = 'YYYY-MM-DD';
const START_YEAR = moment().year() - 1;
const YEARS_FORWARD = 4;

const months = Array
  .from(new Array(12).keys())
  .map(key => key + 1);

const years = [
  ...Array
    .from(new Array(YEARS_FORWARD).keys())
    .map(index => START_YEAR + index)
    .reverse(),
];

const formatLink = (href: string) => (href.startsWith('http'))
  ? href
  : `https://${href}`;

const convertToMoment = (month: number, year: number) => (!!month && !!year)
  ? moment([
    year,
    month - 1,
    1,
  ])
  : null;

const formatDate = (date: any) => date
  ? date.format(DATE_FORMAT)
  : null;

const getInsertAvailability = ({
  fromMonth,
  fromYear,
  insert,
  toMonth,
  toYear,
}: any) => {
  if (insert === dynamicInsertTypes.PAYMENT_NUMBER) {
    return !!fromMonth && !!fromYear;
  }
  if (insert === dynamicInsertTypes.PAYMENT_COUNT) {
    return !!fromMonth && !!fromYear && !!toMonth && !!toYear;
  }
  return false;
};

function Contractor() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const { lang } = useLocationSearch();
  const dispatch: Dispatch<any> = useDispatch();
  const { contractorId } = useParams();
  const inputRef = useRef<any>(null);
  const isMobile = useIsMobile();
  const validateAccess = useAccessValidate();
  const changePage = useChangePage();

  const {
    errorMessage: contractorErrorMessage,
    details: contractor,
    isFailed: isFailedContractor,
    isFailedCreate: isFailedCreateContractor,
    isFailedUpdate: isFailedUpdateContractor,
    isFetching: isFetchingContractor,
    isFetchingCreate: isFetchingCreateContractor,
    isFetchingUpdate: isFetchingUpdateContractor,
    isFinishedCreate: isFinishedCreateContractor,
    isFinishedUpdate: isFinishedUpdateContractor,
  } = useSelector(({
    contractor: reducerContractor,
  }: any) => reducerContractor);

  const {
    errorMessage: compensationErrorMessage,
    list: compensations,
    isFailed: isFailedCompensations,
    isFailedCreate: isFailedCreateCompensation,
    isFailedDelete: isFailedDeleteCompensation,
    isFailedUpdate: isFailedUpdateCompensation,
    isFetching: isFetchingCompensations,
    isFetchingCreate: isFetchingCreateCompensation,
    isFetchingDelete: isFetchingDeleteCompensation,
    isFetchingUpdate: isFetchingUpdateCompensation,
    isFinishedCreate: isFinishedCreateCompensation,
    isFinishedDelete: isFinishedDeleteCompensation,
    isFinishedUpdate: isFinishedUpdateCompensation,
  } = useSelector(({
    compensations: reducerCompensations,
  }: any) => reducerCompensations);

  const {
    isFailed: isFailedCompensationTypes,
    isFetching: isFetchingCompensationTypes,
    list: types,
  } = useSelector(({
    compensationTypes: reducerTypes,
  }: any) => reducerTypes);

  const {
    isFailed: isFailedContractTypes,
    isFetching: isFetchingContractTypes,
    list: contractTypes,
  } = useSelector(({
    contractTypes: reducerContractTypes,
  }: any) => reducerContractTypes);

  const [state, setState]: any = useState({
    address: '',
    addressPol: '',
    bankAddress: '',
    bankUkrFo: '',
    bankUkrFop: '',
    bankUsd: '',
    compactColumnIndex: 1,
    compensationComment: '',
    compensationCommentCaretPosition: 0,
    compensationCurrency: '',
    compensationFromMonth: null,
    compensationFromYear: null,
    compensationRateMeasure: '',
    compensationSum: 0,
    compensationToMonth: null,
    compensationToYear: null,
    compensationTypeId: '',
    contractTypeId: null,
    correspondentBank: '',
    correspondentBankSwift: '',
    deleteCompensationId: null,
    documentsLink: '',
    editCompensationId: null,
    email: '',
    emailPersonal: '',
    fullName: '',
    fullNameUkr: '',
    ibanFo: '',
    ibanFop: '',
    ibanUsd: '',
    inn: '',
    isCreateCompensation: false,
    isEditInfo: !contractorId,
    phone: '',
    showErrorAfterSaveAlert: false,
    showSuccessAfterSaveAlert: false,
    source: '',
    status: statuses.ACTIVE,
    swift: '',
    tab: tabs.DETAILS,
    taxGroup: '',
    validationErrors: [] as string[],
  });

  const isFailedContent = isFailedContractor
    || isFailedCompensationTypes
    || isFailedCompensations
    || isFailedContractTypes;

  const isFetchingContent = isFetchingContractor
    || isFetchingCompensationTypes
    || isFetchingCompensations
    || isFetchingContractTypes;

  const hasCompensations = compensations.length > 0;

  const getValidationErrors = () => {
    const errors = [] as any;

    if (state.isEditInfo && !state.fullName) {
      errors.push(errorTypes.EMPTY_FIELD_FULL_NAME);
    }

    if (state.isEditInfo && !state.email) {
      errors.push(errorTypes.EMPTY_FIELD_EMAIL);
    }

    dynamicInserts.forEach(insert => {
      if (!insert.isAvailable
        && state.compensationComment.includes(insert.label)
      ) {
        errors.push(insertsToErrorTypes[insert.value]);
      }
    });

    return errors;
  };

  const onOpenCreateCompensationDialog = () => setState({
    ...state,
    compensationComment: '',
    compensationCurrency: currencies.USD,
    compensationFromMonth: null,
    compensationFromYear: null,
    compensationRateMeasure: rateMeasureTypes.MONTHLY,
    compensationSum: 0,
    compensationToMonth: null,
    compensationToYear: null,
    compensationTypeId: defaultTypeId,
    isCreateCompensation: true,
  });

  const onCancelEditInfo = () => {
    if (!contractorId) {
      changePage({
        pathname: `${pagesURLs[pages.contractors]}`,
      });
    } else {
      setState({
        ...state,
        isEditInfo: false,
        showErrorAfterSaveAlert: false,
      });
    }
  };

  const prepareContractorPayload = () => ({
    address: state.address,
    addressPol: state.addressPol,
    bankAddress: state.bankAddress,
    bankUkrFo: state.bankUkrFo,
    bankUkrFop: state.bankUkrFop,
    bankUsd: state.bankUsd,
    contractTypeId: state.contractTypeId,
    correspondentBank: state.correspondentBank,
    correspondentBankSwift: state.correspondentBankSwift,
    documentsLink: state.documentsLink,
    email: state.email,
    emailPersonal: state.emailPersonal,
    fullName: state.fullName,
    fullNameUkr: state.fullNameUkr,
    ibanFo: state.ibanFo,
    ibanFop: state.ibanFop,
    ibanUsd: state.ibanUsd,
    inn: state.inn,
    phone: state.phone,
    status: state.status,
    swift: state.swift,
    taxGroup: state.taxGroup,
  });

  const onCloseCreateUpdateCompensationDialog = () => setState({
    ...state,
    editCompensationId: null,
    isCreateCompensation: false,
    showErrorAfterSaveAlert: false,
  });

  const onCloseDeleteCompensationDialog = () => setState({
    ...state,
    deleteCompensationId: null,
    showErrorAfterSaveAlert: false,
  });

  const handleDynamicInsert = (value: any) => {
    const { compensationComment, compensationCommentCaretPosition } = state;
    const updatedComment = compensationComment
      .slice(0, compensationCommentCaretPosition) + value + compensationComment
      .slice(compensationCommentCaretPosition);

    setState({
      ...state,
      compensationComment: updatedComment,
    });

    inputRef.current.focus();
  };

  const getPeriodicityLabel = ({
    fromMonth,
    fromYear,
    toMonth,
    toYear,
    withDuration = false,
  }: any) => {
    const isFrom = !!fromMonth && !!fromYear;
    const isTo = !!toMonth && !!toYear;

    if (isFrom && !isTo) {
      return formatMessage(
        { id: 'periodicity.from' },
        {
          fromMonth: formatMessage({ id: `month.${fromMonth}` }),
          fromYear: fromYear,
        });
    }

    if (!isFrom && isTo) {
      return formatMessage(
        { id: 'periodicity.to' },
        {
          toMonth: formatMessage({ id: `month.${toMonth}` }),
          toYear: toYear,
        });
    }

    if (isFrom && isTo) {
      if (withDuration) {
        const duration = moment(convertToMoment(toMonth, toYear))
          .diff(moment(convertToMoment(fromMonth, fromYear)), 'months') + 1;
        if (duration <= 0) {
          return formatMessage({ id: 'periodicity.negativeWarning' });
        }
        return formatMessage(
          { id: 'periodicity.duration' },
          { duration: duration }
        );
      } else {
        if (fromMonth === toMonth && fromYear === toYear) {
          return formatMessage(
            { id: 'periodicity.oneTime' },
            {
              fromMonth: formatMessage({ id: `month.${fromMonth}` }),
              fromYear: fromYear,
            });
        } else {
          return formatMessage(
            { id: 'periodicity.fromTo' },
            {
              fromMonth: formatMessage({ id: `month.${fromMonth}` }),
              fromYear: fromYear,
              toMonth: formatMessage({ id: `month.${toMonth}` }),
              toYear: toYear,
            });
        }
      }
    }

    return formatMessage({ id: 'periodicity.monthly' });
  };

  const onSaveCompensation = () => {
    const validationErrors = getValidationErrors();
    if (!validationErrors.length) {
      if (state.editCompensationId) {
        dispatch(actionsCompensations.fetchUpdateCompensation({
          comment: state.compensationComment,
          compensationId: state.editCompensationId,
          contractorId,
          currency: state.compensationCurrency,
          from: formatDate(convertToMoment(
            state.compensationFromMonth,
            state.compensationFromYear
          )),
          rateMeasure: state.compensationRateMeasure,
          sum: state.compensationSum,
          to: formatDate(convertToMoment(
            state.compensationToMonth,
            state.compensationToYear
          )),
          typeId: state.compensationTypeId,
        }));
      } else {
        dispatch(actionsCompensations.fetchCreateCompensation({
          comment: state.compensationComment,
          contractorId,
          currency: state.compensationCurrency,
          from: formatDate(convertToMoment(
            state.compensationFromMonth,
            state.compensationFromYear
          )),
          rateMeasure: state.compensationRateMeasure,
          sum: state.compensationSum,
          to: formatDate(convertToMoment(
            state.compensationToMonth,
            state.compensationToYear
          )),
          typeId: state.compensationTypeId,
        }));
      }
    }
    setState({
      ...state,
      validationErrors,
    });
  };

  const onSaveOrUpdateContractor = () => {
    const validationErrors = getValidationErrors();
    if (validationErrors.length === 0) {
      const payload = prepareContractorPayload();
      if (contractorId) {
        dispatch(actionsContractor
          .fetchUpdateContractor({
            ...payload,
            contractorId,
          }));
      } else {
        dispatch(actionsContractor.fetchCreateContractor({ ...payload }));
      }
    }
    setState({
      ...state,
      validationErrors,
    });
  };

  const hasCompensationAccess = useMemo(
    () => validateAccess(authoritiesUI.COMPENSATIONS),
    [validateAccess]
  );

  const orderedAvailableTabs = useMemo(() => (
    [
      tabs.DETAILS,
      tabs.COMPENSATIONS,
    ].filter(tab => validateAccess(authoritiesToTabs[tab]))
  ), []);

  const typeIdsToNames = useMemo(() => types
    .reduce((acc: Object, field: any) => ({
      ...acc,
      [field.id]: field.name,
    }), {}), [types]);

  const contractTypeIdsToNames = useMemo(() => contractTypes
    .reduce((acc: Object, field: any) => ({
      ...acc,
      [field.id]: field.name,
    }), {}), [contractTypes]);

  const defaultTypeId = useMemo(() => (types
    .find((type: any) => type.businessProcess === DEFAULT_COMPENSATION_TYPE)
    ?.id
  ), [types]);

  const contractorRows = useMemo(() => ({
    [contractorInfoFields.FULL_NAME]: {
      label: formatMessage({ id: 'contractor.FULL_NAME' }),
      value: contractor.fullName,
    },
    [contractorInfoFields.FULL_NAME_UKR]: {
      label: formatMessage({ id: 'contractor.FULL_NAME_UKR' }),
      value: contractor.fullNameUkr,
    },
    [contractorInfoFields.EMAIL]: {
      label: formatMessage({ id: 'contractor.EMAIL' }),
      value: contractor.email,
    },
    [contractorInfoFields.PHONE]: {
      label: formatMessage({ id: 'contractor.PHONE' }),
      value: contractor.phone,
    },
    [contractorInfoFields.ADDRESS]: {
      label: formatMessage({ id: 'contractor.ADDRESS' }),
      value: contractor.address,
    },
    [contractorInfoFields.ADDRESS_POL]: {
      label: formatMessage({ id: 'contractor.ADDRESS_POL' }),
      value: contractor.addressPol,
    },
    [contractorInfoFields.EMAIL_PERSONAL]: {
      label: formatMessage({ id: 'contractor.EMAIL_PERSONAL' }),
      value: contractor.emailPersonal,
    },
    [contractorInfoFields.DOCUMENTS_LINK]: {
      label: formatMessage({ id: 'contractor.DOCUMENTS_LINK' }),
      value: contractor.documentsLink
        ? formatLink(contractor.documentsLink)
        : '',
    },
    [contractorInfoFields.STATUS]: {
      label: formatMessage({ id: 'contractor.STATUS' }),
      value: contractor.status,
    },
    [contractorInfoFields.INN]: {
      label: formatMessage({ id: 'contractor.INN' }),
      value: contractor.inn,
    },
    [contractorInfoFields.BANK_UKR_FOP]: {
      label: formatMessage({ id: 'contractor.BANK' }),
      value: contractor.bankUkrFop,
    },
    [contractorInfoFields.BANK_UKR_FO]: {
      label: formatMessage({ id: 'contractor.BANK' }),
      value: contractor.bankUkrFo,
    },
    [contractorInfoFields.IBAN_FOP]: {
      label: formatMessage({ id: 'contractor.IBAN' }),
      value: contractor.ibanFop,
    },
    [contractorInfoFields.IBAN_FO]: {
      label: formatMessage({ id: 'contractor.IBAN' }),
      value: contractor.ibanFo,
    },
    [contractorInfoFields.TAX_GROUP]: {
      label: formatMessage({ id: 'contractor.TAX_GROUP' }),
      value: contractor.taxGroup,
    },
    [contractorInfoFields.BANK_USD]: {
      label: formatMessage({ id: 'contractor.BANK' }),
      value: contractor.bankUsd,
    },
    [contractorInfoFields.BANK_ADDRESS]: {
      label: formatMessage({ id: 'contractor.BANK_ADDRESS' }),
      value: contractor.bankAddress,
    },
    [contractorInfoFields.CONTRACT_TYPE]: {
      label: formatMessage({ id: 'contractor.CONTRACT_TYPE' }),
      value: contractTypeIdsToNames[contractor.contractTypeId],
    },
    [contractorInfoFields.SWIFT]: {
      label: formatMessage({ id: 'contractor.SWIFT' }),
      value: contractor.swift,
    },
    [contractorInfoFields.CORRESPONDENT_BANK]: {
      label: formatMessage({ id: 'contractor.CORRESPONDENT_BANK' }),
      value: contractor.correspondentBank,
    },
    [contractorInfoFields.CORRESPONDENT_BANK_SWIFT]: {
      label: formatMessage({ id: 'contractor.CORRESPONDENT_BANK_SWIFT' }),
      value: contractor.correspondentBankSwift,
    },
    [contractorInfoFields.IBAN_USD]: {
      label: formatMessage({ id: 'contractor.IBAN' }),
      value: contractor.ibanUsd,
    },
    [contractorInfoFields.SOURCE]: {
      label: formatMessage({ id: 'contractor.SOURCE' }),
      value: contractor.source,
    },
  }), [contractor, contractTypes, lang]);

  const compensationsRows = useMemo(() => (
    compensations
      .map((compensation: any) => ({
        compensationId: compensation.id,
        [TABLE_COLUMNS.type]: {
          label: typeIdsToNames[compensation.typeId],
          value: compensation.typeId,
        },
        [TABLE_COLUMNS.sum]: {
          caption: formatMessage({
            id: `rateMeasure.${compensation.rateMeasure}`,
          }),
          currency: compensation.currency,
          label: `${Intl.NumberFormat(
            locales[lang],
            {
              currency: compensation.currency,
              style: 'currency',
            }).format(compensation.sum)}`,
          rateMeasure: compensation.rateMeasure,
          value: compensation.sum,
        },
        [TABLE_COLUMNS.periodicity]: {
          caption: compensation.duration
            ? formatMessage(
              { id: 'periodicity.duration' },
              { duration: compensation.duration }
            )
            : null,
          fromMonth: compensation.fromMonth,
          fromYear: compensation.fromYear,
          label: getPeriodicityLabel({
            fromMonth: compensation.fromMonth,
            fromYear: compensation.fromYear,
            toMonth: compensation.toMonth,
            toYear: compensation.toYear,
          }),
          toMonth: compensation.toMonth,
          toYear: compensation.toYear,
        },
        [TABLE_COLUMNS.comment]: {
          value: compensation.comment,
        },
      }))
  ), [
    compensations,
    types,
    lang,
  ]);

  const dynamicInserts = useMemo(() => (
    dynamicInsertsList
      .map((insert: any) => ({
        hint: formatMessage({
          id: `compensation.COMMENT.hint.${insert}`,
        }),
        isAvailable: getInsertAvailability({
          fromMonth: state.compensationFromMonth,
          fromYear: state.compensationFromYear,
          insert,
          toMonth: state.compensationToMonth,
          toYear: state.compensationToYear,
        }),
        label: formatMessage({
          id: `compensation.COMMENT.${insert}`,
        }),
        value: insert,
      }))
  ), [
    state.compensationFromMonth,
    state.compensationFromYear,
    state.compensationToMonth,
    state.compensationToYear,
  ]);

  useEffect(() => {
    if (contractorId) {
      if (hasCompensationAccess) {
        dispatch(actionsCompensations.fetchCompensations(contractorId));
      }
      dispatch(actionsContractor.fetchContractor(contractorId));
    }
    dispatch(actionsContractTypes.fetchContractTypes(true));
  }, [contractorId]);

  useEffect(() => {
    let {
      isEditInfo,
      showSuccessAfterSaveAlert,
    } = state;

    if (isFinishedUpdateContractor) {
      isEditInfo = isFailedUpdateContractor;
      showSuccessAfterSaveAlert = !isFailedUpdateContractor;
    }

    setState({
      ...state,
      isEditInfo,
      showErrorAfterSaveAlert: isFailedUpdateContractor,
      showSuccessAfterSaveAlert,
    });
  }, [isFetchingUpdateContractor]);

  useEffect(() => {
    if (isFinishedCreateContractor) {
      if (isFailedCreateContractor) {
        setState(({
          ...state,
          showErrorAfterSaveAlert: isFailedCreateContractor,
        }));
      } else {
        changePage({ pathname: `${pagesURLs[pages.contractors]}` });
      }
    }
  }, [isFetchingCreateContractor]);


  useEffect(() => {
    let {
      editCompensationId,
      showSuccessAfterSaveAlert,
    } = state;

    if (isFinishedUpdateCompensation) {
      editCompensationId = isFailedUpdateCompensation
        ? state.editCompensationId
        : null;
      showSuccessAfterSaveAlert = !isFailedUpdateCompensation;
    }

    setState({
      ...state,
      editCompensationId,
      showErrorAfterSaveAlert: isFailedUpdateCompensation,
      showSuccessAfterSaveAlert,
    });
  }, [isFetchingUpdateCompensation]);

  useEffect(() => {
    let {
      isCreateCompensation,
      showSuccessAfterSaveAlert,
    } = state;

    if (isFinishedCreateCompensation) {
      isCreateCompensation = isFailedCreateCompensation;
      showSuccessAfterSaveAlert = !isFailedCreateCompensation;
    }

    setState({
      ...state,
      isCreateCompensation,
      showErrorAfterSaveAlert: isFailedCreateCompensation,
      showSuccessAfterSaveAlert,
    });
  }, [isFetchingCreateCompensation]);

  useEffect(() => {
    let {
      deleteCompensationId,
      editCompensationId,
      showSuccessAfterSaveAlert,
    } = state;

    if (isFinishedDeleteCompensation) {
      showSuccessAfterSaveAlert = !isFailedDeleteCompensation;
      editCompensationId = isFailedDeleteCompensation
        ? state.editCompensationId
        : null;
      deleteCompensationId = isFailedDeleteCompensation
        ? state.deleteCompensationId
        : null;
    }

    setState({
      ...state,
      deleteCompensationId,
      editCompensationId,
      showCreateDialog: false,
      showErrorAfterSaveAlert: isFailedDeleteCompensation,
      showSuccessAfterSaveAlert,
    });
  }, [isFetchingDeleteCompensation]);

  useEffect(() => {
    if (hasCompensationAccess && !types.length) {
      dispatch(actionsTypes.fetchTypes());
    }
    return () => {
      dispatch(actionsSetup.resetReducersData());
    };
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({
            id: 'contractors',
          })}
          to={{
            pathname: `${pagesURLs[pages.contractors]}`,
          }}
          variant="link"
        />
        {contractorId
          ? (
            <Breadcrumb
              label={contractor.fullName}
              variant="text"
            />
          )
          : (
            <Breadcrumb
              label={formatMessage({
                id: 'contractor.create.title',
              })}
              variant="text"
            />
          )
        }
      </Breadcrumbs>
      <div>
        <div className={classes.bar}>
          {contractorId && (
            <Tabs
              value={state.tab}
              onChange={(event, newValue) => setState((prevState: any) => ({
                ...prevState,
                editCompensationId: null,
                isEditInfo: false,
                showErrorAfterSaveAlert: false,
                showSuccessAfterSaveAlert: false,
                tab: newValue,
              }))}
            >
              {orderedAvailableTabs.map(tab => (
                <Tab
                  disabled={isFetchingUpdateContractor}
                  label={formatMessage({ id: `tab.${tab}` })}
                  value={tab}
                />
              ))}
            </Tabs>
          )}
        </div>
        {(isFetchingContent || isFailedContent) && (
          <Loading variant={isFailedContent ? 'error' : 'loading'}>
            {isFailedContent && (
              <Typography color="secondary" variant="subtitle">
                {formatMessage({ id: 'loading.error' })}
              </Typography>
            )}
          </Loading>
        )}
        {(!isFetchingContent && !isFailedContent) && (
          <>
            {/* DETAILS TAB */}
            {state.tab === tabs.DETAILS && (
              <Card
                disablePaddings
                variant={state.isEditInfo ? 'edit' : 'paper'}
              >
                <div className={classes.contractorContainer}>
                  {/* Personal Details */}
                  <CardTitle>
                    <Typography
                      color="secondary"
                      variant="title"
                    >
                      {formatMessage({ id: 'personalInfo' })}
                    </Typography>
                    {!state.isEditInfo && (
                      <IconButton
                        disableHoverSpace
                        onClick={() => setState({
                          ...state,
                          address: contractor.address,
                          addressPol: contractor.addressPol,
                          bankAddress: contractor.bankAddress,
                          bankUkrFo: contractor.bankUkrFo,
                          bankUkrFop: contractor.bankUkrFop,
                          bankUsd: contractor.bankUsd,
                          contractTypeId: contractor.contractTypeId,
                          correspondentBank: contractor.correspondentBank,
                          correspondentBankSwift: contractor
                            .correspondentBankSwift,
                          documentsLink: contractor.documentsLink,
                          email: contractor.email,
                          emailPersonal: contractor.emailPersonal,
                          fullName: contractor.fullName,
                          fullNameUkr: contractor.fullNameUkr,
                          ibanFo: contractor.ibanFo,
                          ibanFop: contractor.ibanFop,
                          ibanUsd: contractor.ibanUsd,
                          inn: contractor.inn,
                          isEditInfo: true,
                          phone: contractor.phone,
                          source: contractor.source,
                          status: contractor.status,
                          swift: contractor.swift,
                          taxGroup: contractor.taxGroup,
                        })}
                      >
                        <IconEdit size={20} />
                      </IconButton>
                    )}
                    {state.isEditInfo && (
                      <IconButton
                        disableHoverSpace
                        onClick={onCancelEditInfo}
                      >
                        <IconClose size={24} />
                      </IconButton>
                    )}
                  </CardTitle>
                  <Divider />
                  <CardContent>
                    {!state.isEditInfo && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.FULL_NAME]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.FULL_NAME]
                              .value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .FULL_NAME_UKR].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields
                              .FULL_NAME_UKR].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.EMAIL]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.EMAIL].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.PHONE]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.PHONE]
                              .value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.ADDRESS]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.ADDRESS]
                              .value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .ADDRESS_POL].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.ADDRESS_POL]
                              .value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .EMAIL_PERSONAL].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields
                              .EMAIL_PERSONAL].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .DOCUMENTS_LINK].label}
                            </Typography>
                          </div>
                          <Link
                            underline
                            href={contractorRows[contractorInfoFields
                              .DOCUMENTS_LINK].value}
                            target="_blank"
                          >
                            <Typography wordBreak="break-all">
                              {contractorRows[contractorInfoFields
                                .DOCUMENTS_LINK].value}
                            </Typography>
                          </Link>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.STATUS]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {formatMessage({
                              id: `contractor.status.${
                                contractorRows[contractorInfoFields
                                  .STATUS].value}`,
                            })}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .CONTRACT_TYPE].label
                              }
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.CONTRACT_TYPE]
                              .value || formatMessage({ id: 'notApplied' })}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {state.isEditInfo && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.FULL_NAME]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            AdornmentEnd={
                              state.validationErrors
                                .includes(errorTypes.EMPTY_FIELD_FULL_NAME) && (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={formatMessage({
                                    id: `validationError.${errorTypes
                                      .EMPTY_FIELD_FULL_NAME}`,
                                  })}
                                >
                                  <IconError
                                    color={theme.colors.redDark}
                                    size={20}
                                  />
                                </Tooltip>)
                            }
                            disabled={state.source !== sources.NONE
                              && !!contractorId}
                            fullWidth
                            isError={state.validationErrors
                              .includes(errorTypes.EMPTY_FIELD_FULL_NAME)}
                            onChange={({ target }) => setState({
                              ...state,
                              fullName: target.value,
                            })}
                            size="small"
                            value={state.fullName}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .FULL_NAME_UKR].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              fullNameUkr: target.value,
                            })}
                            size="small"
                            value={state.fullNameUkr}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.EMAIL]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            AdornmentEnd={
                              state.validationErrors
                                .includes(errorTypes.EMPTY_FIELD_EMAIL) && (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={formatMessage({
                                    id: `validationError.${errorTypes
                                      .EMPTY_FIELD_EMAIL}`,
                                  })}
                                >
                                  <IconError
                                    color={theme.colors.redDark}
                                    size={20}
                                  />
                                </Tooltip>)
                            }
                            disabled={state.source !== sources.NONE
                              && !!contractorId}
                            fullWidth
                            isError={state.validationErrors
                              .includes(errorTypes.EMPTY_FIELD_EMAIL)}
                            onChange={({ target }) => setState({
                              ...state,
                              email: target.value,
                            })}
                            size="small"
                            value={state.email}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.PHONE]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              phone: target.value,
                            })}
                            size="small"
                            value={state.phone}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.ADDRESS]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              address: target.value,
                            })}
                            size="small"
                            value={state.address}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .ADDRESS_POL].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              addressPol: target.value,
                            })}
                            size="small"
                            value={state.addressPol}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .EMAIL_PERSONAL].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              emailPersonal: target.value,
                            })}
                            size="small"
                            value={state.emailPersonal}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .DOCUMENTS_LINK].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              documentsLink: target.value,
                            })}
                            size="small"
                            value={state.documentsLink}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .STATUS].label}
                            </Typography>
                          </div>
                          <Select
                            disabled={state.source !== sources.NONE
                              && !!contractorId}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              status: target.value,
                            })}
                            size="small"
                            value={state.status}
                            variant="outlined"
                          >
                            {contractorStatuses.map((cs) => (
                              <MenuItem value={cs}>
                                <Typography>
                                  {formatMessage({
                                    id: `contractor.status.${cs}`,
                                  })}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {formatMessage({
                                id: 'contractor.CONTRACT_TYPE',
                              })}
                            </Typography>
                          </div>
                          <Select
                            disabled={isFetchingContent}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              contractTypeId: target.value,
                            })}
                            size="small"
                            value={state.contractTypeId}
                            variant="outlined"
                          >
                            <MenuItem value={null}>
                              <Typography>
                                {formatMessage({ id: 'notApplied' })}
                              </Typography>
                            </MenuItem>
                            {contractTypes.map((type: any) => (
                              <MenuItem value={type.id}>
                                <Typography>
                                  {type.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    )}
                  </CardContent>
                  <div />
                  {/* Tax Details */}
                  <CardTitle>
                    <Typography
                      color="secondary"
                      variant="title"
                    >
                      {formatMessage({ id: 'taxDetails' })}
                    </Typography>
                  </CardTitle>
                  <Divider />
                  <CardContent>
                    {!state.isEditInfo && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.INN].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.INN].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.TAX_GROUP]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.TAX_GROUP]
                              .value}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {state.isEditInfo && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.INN].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              inn: target.value,
                            })}
                            size="small"
                            value={state.inn}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.TAX_GROUP]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              taxGroup: target.value,
                            })}
                            size="small"
                            value={state.taxGroup}
                            variant="standard"
                          />
                        </div>
                      </div>
                    )}
                  </CardContent>
                  <div />
                  {/* Bank Details (UAH-FOP) */}
                  <CardTitle>
                    <Typography
                      color="secondary"
                      variant="title"
                    >
                      {formatMessage({ id: 'bankDetailsUkrFop' })}
                    </Typography>
                  </CardTitle>
                  <Divider />
                  <CardContent>
                    {!state.isEditInfo && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.BANK_UKR_FOP]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.BANK_UKR_FOP]
                              .value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.IBAN_FOP]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.IBAN_FOP]
                              .value}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {state.isEditInfo && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.BANK_UKR_FOP]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              bankUkrFop: target.value,
                            })}
                            size="small"
                            value={state.bankUkrFop}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.IBAN_FOP]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              ibanFop: target.value,
                            })}
                            size="small"
                            value={state.ibanFop}
                            variant="standard"
                          />
                        </div>
                      </div>
                    )}
                  </CardContent>
                  <div />
                  {/* Bank Details (UAH-FO) */}
                  <CardTitle>
                    <Typography
                      color="secondary"
                      variant="title"
                    >
                      {formatMessage({ id: 'bankDetailsUkrFo' })}
                    </Typography>
                  </CardTitle>
                  <Divider />
                  <CardContent>
                    {!state.isEditInfo && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.BANK_UKR_FO]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.BANK_UKR_FO]
                              .value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.IBAN_FO]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.IBAN_FO].value}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {state.isEditInfo && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.BANK_UKR_FO]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              bankUkrFo: target.value,
                            })}
                            size="small"
                            value={state.bankUkrFo}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.IBAN_FO]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              ibanFo: target.value,
                            })}
                            size="small"
                            value={state.ibanFo}
                            variant="standard"
                          />
                        </div>
                      </div>
                    )}
                  </CardContent>
                  <div />
                  {/* Bank Details (USD) */}
                  <CardTitle>
                    <Typography
                      color="secondary"
                      variant="title"
                    >
                      {formatMessage({ id: 'bankDetailsUsd' })}
                    </Typography>
                  </CardTitle>
                  <Divider />
                  <CardContent>
                    {!state.isEditInfo && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.BANK_USD]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.BANK_USD]
                              .value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .BANK_ADDRESS].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.BANK_ADDRESS]
                              .value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.SWIFT]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.SWIFT].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .CORRESPONDENT_BANK].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields
                              .CORRESPONDENT_BANK].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .CORRESPONDENT_BANK_SWIFT].label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields
                              .CORRESPONDENT_BANK_SWIFT].value}
                          </Typography>
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.IBAN_USD]
                                .label}
                            </Typography>
                          </div>
                          <Typography wordBreak="break-word">
                            {contractorRows[contractorInfoFields.IBAN_USD]
                              .value}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {state.isEditInfo && (
                      <div
                        className={isMobile
                          ? classes.infoContainerMobile
                          : classes.infoContainer}
                      >
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.BANK_USD]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              bankUsd: target.value,
                            })}
                            size="small"
                            value={state.bankUsd}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .BANK_ADDRESS].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              bankAddress: target.value,
                            })}
                            size="small"
                            value={state.bankAddress}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.SWIFT]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              swift: target.value,
                            })}
                            size="small"
                            value={state.swift}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .CORRESPONDENT_BANK].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              correspondentBank: target.value,
                            })}
                            size="small"
                            value={state.correspondentBank}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields
                                .CORRESPONDENT_BANK_SWIFT].label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              correspondentBankSwift: target.value,
                            })}
                            size="small"
                            value={state.correspondentBankSwift}
                            variant="standard"
                          />
                        </div>
                        <div
                          className={isMobile
                            ? classes.contractorMobileRow
                            : classes.row}
                        >
                          <div className={isMobile ? '' : classes.cell}>
                            <Typography
                              color="secondary"
                              variant={isMobile ? 'subtitle' : 'default'}
                            >
                              {contractorRows[contractorInfoFields.IBAN_USD]
                                .label}
                            </Typography>
                          </div>
                          <TextField
                            disabled={isFetchingUpdateContractor}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              ibanUsd: target.value,
                            })}
                            size="small"
                            value={state.ibanUsd}
                            variant="standard"
                          />
                        </div>
                      </div>
                    )}
                  </CardContent>
                  <div />
                  {/* Source */}
                  {contractorId && (
                    <>
                      <CardTitle>
                        <Typography
                          color="secondary"
                          variant="title"
                        >
                          {formatMessage({ id: 'sourceDetail' })}
                        </Typography>
                      </CardTitle>
                      <Divider />
                      <CardContent>
                        {!state.isEditInfo && (
                          <div
                            className={isMobile
                              ? classes.infoContainerMobile
                              : classes.infoContainer}
                          >
                            <div
                              className={isMobile
                                ? classes.contractorMobileRow
                                : classes.row}
                            >
                              <div className={isMobile ? '' : classes.cell}>
                                <Typography
                                  color="secondary"
                                  variant={isMobile ? 'subtitle' : 'default'}
                                >
                                  {contractorRows[contractorInfoFields.SOURCE]
                                    .label}
                                </Typography>
                              </div>
                              <Typography wordBreak="break-word">
                                {formatMessage({
                                  id: `contractor.source.${
                                    contractorRows[contractorInfoFields
                                      .SOURCE].value}`,
                                })}
                              </Typography>
                            </div>
                          </div>
                        )}
                        {state.isEditInfo && (
                          <div
                            className={isMobile
                              ? classes.infoContainerMobile
                              : classes.infoContainer}
                          >
                            <div
                              className={isMobile
                                ? classes.contractorMobileRow
                                : classes.row}
                            >
                              <div className={isMobile ? '' : classes.cell}>
                                <Typography
                                  color="secondary"
                                  variant={isMobile ? 'subtitle' : 'default'}
                                >
                                  {contractorRows[contractorInfoFields.SOURCE]
                                    .label}
                                </Typography>
                              </div>
                              <TextField
                                disabled
                                fullWidth
                                onChange={({ target }) => setState({
                                  ...state,
                                  source: target.value,
                                })}
                                size="small"
                                value={formatMessage({
                                  id: `contractor.source.${
                                    state.source}`,
                                })}
                                variant="standard"
                              />
                            </div>
                          </div>
                        )}
                      </CardContent>
                    </>
                  )}
                  <div />
                  {state.isEditInfo && (
                    <CardActions>
                      <Button
                        onClick={onCancelEditInfo}
                        variant="secondary"
                      >
                        <Typography color="inherit">
                          {formatMessage({ id: 'cancel' })}
                        </Typography>
                      </Button>
                      <Button
                        isLoading={isFetchingUpdateContractor
                          || isFetchingCreateContractor}
                        onClick={onSaveOrUpdateContractor}
                        variant="primary"
                      >
                        <Typography color="inherit">
                          {formatMessage({ id: 'save' })}
                        </Typography>
                      </Button>
                    </CardActions>
                  )}
                </div>
              </Card>
            )}
            {/* COMPENSATIONS TAB */}
            {state.tab === tabs.COMPENSATIONS && (
              <>
                {!hasCompensations && (
                  <Card>
                    <CardContent>
                      <Loading variant="noData">
                        <Typography
                          color="secondary"
                          variant="title"
                        >
                          {formatMessage({ id: 'noCompensations' })}
                        </Typography>
                      </Loading>
                    </CardContent>
                    <CardActions>
                      <Button
                        disabled={isFetchingUpdateCompensation}
                        onClick={onOpenCreateCompensationDialog}
                        variant="primary"
                        startIcon={(
                          <IconPlus
                            color="button"
                            size={20}
                          />
                        )}
                      >
                        <Typography color="inherit">
                          {formatMessage({ id: 'add' })}
                        </Typography>
                      </Button>
                    </CardActions>
                  </Card>
                )}
                {hasCompensations && (
                  <div className={classes.container}>
                    <Card>
                      <CardTitle>
                        <div>
                          <Typography color="secondary">
                            {formatMessage({
                              id: 'compensation.create.subtitle',
                            })}
                          </Typography>
                        </div>
                        <div>
                          <Button
                            disabled={isFetchingUpdateCompensation}
                            onClick={onOpenCreateCompensationDialog}
                            variant="primary"
                            startIcon={(
                              <IconPlus
                                color="button"
                                size={20}
                              />
                            )}
                          >
                            <Typography color="inherit">
                              {formatMessage({ id: 'add' })}
                            </Typography>
                          </Button>
                        </div>
                      </CardTitle>
                    </Card>
                    <Card disablePaddings>
                      <Table
                        compact={isMobile}
                        compactColumnIndex={state.compactColumnIndex}
                        fixed
                        onChangeCompactColumnIndex={(index) => setState({
                          ...state,
                          compactColumnIndex: index,
                        })}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCellHead
                              compactVariant="static"
                            >
                              <Typography
                                color="secondary"
                                noWrap
                                variant="caption"
                              >
                                {formatMessage({
                                  id: `tableColumn.${TABLE_COLUMNS.type}`,
                                })}
                              </Typography>
                            </TableCellHead>
                            <TableCellHead
                              compactVariant="dynamic"
                              size={isMobile ? undefined : 'mediumField'}
                            >
                              <Typography
                                color="secondary"
                                noWrap
                                variant="caption"
                              >
                                {formatMessage({
                                  id: `tableColumn.${TABLE_COLUMNS.sum}`,
                                })}
                              </Typography>
                            </TableCellHead>
                            <TableCellHead
                              compactVariant="dynamic"
                              size={isMobile ? undefined : 'bigField'}
                            >
                              <Typography
                                color="secondary"
                                noWrap
                                variant="caption"
                              >
                                {formatMessage({
                                  id: `tableColumn.${TABLE_COLUMNS
                                    .periodicity}`,
                                })}
                              </Typography>
                            </TableCellHead>
                            {!isMobile && (
                              <TableCellHead size="compactField">
                                <Typography
                                  align="center"
                                  color="secondary"
                                  noWrap
                                  variant="caption"
                                >
                                  {formatMessage({
                                    id: `tableColumn.${TABLE_COLUMNS.actions}`,
                                  })}
                                </Typography>
                              </TableCellHead>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {compensationsRows.map((row: any) => (
                            <TableRow
                              hover
                              onClick={isMobile
                                ? () => setState({
                                  ...state,
                                  compensationComment: row[TABLE_COLUMNS
                                    .comment].value,
                                  compensationCurrency: row[TABLE_COLUMNS
                                    .sum].currency,
                                  compensationFromMonth: row[TABLE_COLUMNS
                                    .periodicity].fromMonth,
                                  compensationFromYear: row[TABLE_COLUMNS
                                    .periodicity].fromYear,
                                  compensationRateMeasure: row[TABLE_COLUMNS
                                    .sum].rateMeasure,
                                  compensationSum: row[TABLE_COLUMNS
                                    .sum].value,
                                  compensationToMonth: row[TABLE_COLUMNS
                                    .periodicity].toMonth,
                                  compensationToYear: row[TABLE_COLUMNS
                                    .periodicity].toYear,
                                  compensationTypeId: row[TABLE_COLUMNS
                                    .type].value,
                                  editCompensationId: row.compensationId,
                                })
                                : undefined
                              }
                            >
                              <TableCell>
                                <Typography>
                                  {row[TABLE_COLUMNS.type].label}
                                </Typography>
                                <Typography
                                  color="secondary"
                                  variant="caption"
                                  wordBreak="break-word"
                                >
                                  {row[TABLE_COLUMNS.comment].value}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {row[TABLE_COLUMNS.sum].label}
                                </Typography>
                                <Typography
                                  color="secondary"
                                  variant="caption"
                                >
                                  {row[TABLE_COLUMNS.sum].caption}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {row[TABLE_COLUMNS.periodicity].label}
                                </Typography>
                                <Typography
                                  color="secondary"
                                  variant="caption"
                                >
                                  {row[TABLE_COLUMNS.periodicity].caption}
                                </Typography>
                              </TableCell>
                              {!isMobile && (
                                <TableCellActions>
                                  <IconButton
                                    disabled={isFetchingUpdateCompensation}
                                    disableHoverSpace
                                    onClick={() => setState({
                                      ...state,
                                      compensationComment: row[TABLE_COLUMNS
                                        .comment].value,
                                      compensationCurrency: row[TABLE_COLUMNS
                                        .sum].currency,
                                      compensationFromMonth: row[TABLE_COLUMNS
                                        .periodicity].fromMonth,
                                      compensationFromYear: row[TABLE_COLUMNS
                                        .periodicity].fromYear,
                                      compensationRateMeasure: row[TABLE_COLUMNS
                                        .sum].rateMeasure,
                                      compensationSum: row[TABLE_COLUMNS
                                        .sum].value,
                                      compensationToMonth: row[TABLE_COLUMNS
                                        .periodicity].toMonth,
                                      compensationToYear: row[TABLE_COLUMNS
                                        .periodicity].toYear,
                                      compensationTypeId: row[TABLE_COLUMNS
                                        .type].value,
                                      editCompensationId: row.compensationId,
                                    })}
                                  >
                                    <IconEdit size={20} />
                                  </IconButton>
                                  <IconButton
                                    disabled={isFetchingDeleteCompensation
                                    || isFetchingUpdateCompensation}
                                    disableHoverSpace
                                    onClick={() => setState({
                                      ...state,
                                      deleteCompensationId: row.compensationId,
                                    })}
                                  >
                                    <IconDelete size={20} />
                                  </IconButton>
                                </TableCellActions>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Card>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      {/* CREATE/UPDATE COMPENSATION. DESKTOP */}
      {!isMobile && (
        <Dialog
          onClose={onCloseCreateUpdateCompensationDialog}
          open={state.isCreateCompensation || !!state.editCompensationId}
        >
          {state.showErrorAfterSaveAlert && (
            <Card variant="error">
              <CardTitle>
                <Typography color="error">
                  {formatMessage(
                    {
                      id: compensationErrorMessage ? 'error' : 'loading.error',
                    },
                    {
                      error: compensationErrorMessage,
                    }
                  )}
                </Typography>
              </CardTitle>
            </Card>
          )}
          <Card variant="edit">
            <CardTitle>
              <div>
                <Typography variant="title">
                  {formatMessage({
                    id: state.editCompensationId
                      ? 'compensation.update.title'
                      : 'compensation.create.title',
                  })}
                </Typography>
                <Typography color="secondary">
                  {formatMessage({
                    id: state.editCompensationId
                      ? 'compensation.update.subtitle'
                      : 'compensation.create.subtitle',
                  })}
                </Typography>
              </div>
              <IconButton onClick={onCloseCreateUpdateCompensationDialog}>
                <IconClose size={24} />
              </IconButton>
            </CardTitle>
            <div />
            <CardContent>
              <div className={classes.dialogContent}>
                <div className={classes.row}>
                  <div className={classes.dialogCell}>
                    <Typography color="secondary">
                      {formatMessage({ id: 'compensation.SUM' })}
                    </Typography>
                  </div>
                  <div className={classes.dialogSum}>
                    <div className={classes.compensationSum}>
                      <TextField
                        disabled={isFetchingCreateCompensation
                         || isFetchingUpdateCompensation}
                        inputType="number"
                        onChange={({ target }) => setState({
                          ...state,
                          compensationSum: target.value,
                        })}
                        size="small"
                        value={state.compensationSum}
                        variant="outlined"
                      />
                    </div>
                    <Select
                      disabled={isFetchingCreateCompensation
                       || isFetchingUpdateCompensation}
                      disableUnderline
                      onChange={({ target }) => setState({
                        ...state,
                        compensationCurrency: target.value,
                      })}
                      value={state.compensationCurrency}
                    >
                      {currenciesList.map(currency => (
                        <MenuItem value={currency}>
                          <Typography>
                            {currency}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography
                      color="secondary"
                      variant="subtitle"
                    >
                      /
                    </Typography>
                    <Select
                      disabled={isFetchingCreateCompensation
                       || isFetchingUpdateCompensation}
                      disableUnderline
                      onChange={({ target }) => setState({
                        ...state,
                        compensationRateMeasure: target.value,
                      })}
                      value={state.compensationRateMeasure}
                    >
                      {rateMeasureTypesList.map(measureType => (
                        <MenuItem value={measureType}>
                          <Typography>
                            {formatMessage({
                              id: `rateMeasure.${measureType}`,
                            })}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.dialogCell}>
                    <Typography color="secondary">
                      {formatMessage({ id: 'compensation.TYPE' })}
                    </Typography>
                  </div>
                  <Select
                    disabled={isFetchingCreateCompensation
                      || isFetchingUpdateCompensation}
                    fullWidth
                    onChange={({ target }) => setState({
                      ...state,
                      compensationTypeId: target.value,
                    })}
                    size="small"
                    value={state.compensationTypeId}
                  >
                    {types.map((type: any) => (
                      <MenuItem value={type.id}>
                        <Typography>
                          {type.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className={classes.row}>
                  <div className={classes.dialogCell}>
                    <Typography color="secondary">
                      {formatMessage({ id: 'compensation.PERIODICITY' })}
                    </Typography>
                  </div>
                  <div className={classes.dialogPeriodicity}>
                    <div className={classes.selectPeriodicity}>
                      <Select
                        disabled={isFetchingCreateCompensation
                          || isFetchingUpdateCompensation}
                        fullWidth
                        onChange={({ target }) => setState({
                          ...state,
                          compensationFromMonth: target.value,
                        })}
                        size="small"
                        value={state.compensationFromMonth}
                      >
                        <MenuItem value={null}>
                          <Typography>
                            {formatMessage({ id: 'NA' })}
                          </Typography>
                        </MenuItem>
                        {months.map((month) => (
                          <MenuItem value={month}>
                            <Typography>
                              {formatMessage({ id: `month.${month}` })}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className={classes.selectYear}>
                      <Select
                        disabled={isFetchingCreateCompensation
                          || isFetchingUpdateCompensation}
                        fullWidth
                        onChange={({ target }) => setState({
                          ...state,
                          compensationFromYear: target.value,
                        })}
                        size="small"
                        value={state.compensationFromYear}
                      >
                        <MenuItem value={null}>
                          <Typography>
                            {formatMessage({ id: 'NA' })}
                          </Typography>
                        </MenuItem>
                        {years.map(year => (
                          <MenuItem value={year}>
                            <Typography>
                              {year}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <Typography color="secondary">
                      <strong>{'—'}</strong>
                    </Typography>
                    <div className={classes.selectPeriodicity}>
                      <Select
                        disabled={isFetchingCreateCompensation
                          || isFetchingUpdateCompensation}
                        fullWidth
                        onChange={({ target }) => setState({
                          ...state,
                          compensationToMonth: target.value,
                        })}
                        size="small"
                        value={state.compensationToMonth}
                      >
                        <MenuItem value={null}>
                          <Typography>
                            {formatMessage({ id: 'NA' })}
                          </Typography>
                        </MenuItem>
                        {months.map((month) => (
                          <MenuItem value={month}>
                            <Typography>
                              {formatMessage({ id: `month.${month}` })}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className={classes.selectYear}>
                      <Select
                        disabled={isFetchingCreateCompensation
                          || isFetchingUpdateCompensation}
                        fullWidth
                        onChange={({ target }) => setState({
                          ...state,
                          compensationToYear: target.value,
                        })}
                        size="small"
                        value={state.compensationToYear}
                      >
                        <MenuItem value={null}>
                          <Typography>
                            {formatMessage({ id: 'NA' })}
                          </Typography>
                        </MenuItem>
                        {years.map(year => (
                          <MenuItem value={year}>
                            <Typography>
                              {year}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.dialogCell}>
                    <Typography color="secondary">
                      {formatMessage({ id: 'compensation.duration' })}
                    </Typography>
                  </div>
                  <Typography>
                    {getPeriodicityLabel({
                      fromMonth: state.compensationFromMonth,
                      fromYear: state.compensationFromYear,
                      toMonth: state.compensationToMonth,
                      toYear: state.compensationToYear,
                      withDuration: true,
                    })}
                  </Typography>
                </div>
                <div className={classes.rowCaptionContainer}>
                  <div className={classes.row}>
                    <div className={classes.dialogCell}>
                      <Typography color="secondary">
                        {formatMessage({ id: 'compensation.COMMENT' })}
                      </Typography>
                    </div>
                    <TextField
                      AdornmentEnd={
                        (state.validationErrors.includes(errorTypes
                          .INVALID_INSERT_COUNT) || state.validationErrors
                          .includes(errorTypes.INVALID_INSERT_NUMBER))
                        && (
                          <Tooltip
                            arrow
                            placement="top"
                            title={(
                              <>
                                {state.validationErrors.includes(errorTypes
                                  .INVALID_INSERT_NUMBER) && (
                                  <Typography
                                    color="inherit"
                                    variant="caption"
                                  >
                                    {formatMessage({
                                      id: `validationError.${errorTypes
                                        .INVALID_INSERT_NUMBER}`,
                                    })}
                                  </Typography>
                                )}
                                {state.validationErrors.includes(errorTypes
                                  .INVALID_INSERT_COUNT) && (
                                  <Typography
                                    color="inherit"
                                    variant="caption"
                                  >
                                    {formatMessage({
                                      id: `validationError.${errorTypes
                                        .INVALID_INSERT_COUNT}`,
                                    })}
                                  </Typography>
                                )}
                              </>
                            )}
                          >
                            <IconError
                              color={theme.colors.redDark}
                              size={20}
                            />
                          </Tooltip>
                        )
                      }
                      disabled={isFetchingCreateCompensation
                        || isFetchingUpdateCompensation}
                      fullWidth
                      inputRef={inputRef}
                      isError={state.validationErrors.includes(errorTypes
                        .INVALID_INSERT_COUNT) || state.validationErrors
                        .includes(errorTypes.INVALID_INSERT_NUMBER)}
                      onChange={({ target }) => setState((prevState: any) => ({
                        ...prevState,
                        compensationComment: target.value,
                      }))}
                      onSelect={() => setState((prevState: any) => ({
                        ...prevState,
                        compensationCommentCaretPosition: inputRef.current
                          .selectionStart,
                      }))}
                      multiline
                      placeholder={formatMessage({
                        id: 'compensation.COMMENT.placeholder',
                      })}
                      size="small"
                      value={state.compensationComment}
                      variant="standard"
                    />
                  </div>
                  <div className={classes.row}>
                    <div className={classes.dialogCell} />
                    <div>
                      <Typography color="secondary">
                        {formatMessage({
                          id: 'compensation.COMMENT.hint',
                        })}
                      </Typography>
                      {dynamicInserts.map(insert => (
                        <div className={classes.dynamicInsertRow}>
                          <Typography color="secondary">
                            -
                          </Typography>
                          <div
                            className={classNames(
                              classes.dynamicInsert,
                              insert.isAvailable && classes.clickable
                            )}
                            onClick={({ target }: any) => insert.isAvailable
                              ? handleDynamicInsert(target.innerText)
                              : undefined}
                            role="button"
                            tabIndex={0}
                          >
                            <Typography
                              color={insert.isAvailable ? 'info' : 'secondary'}
                            >
                              <strong>
                                {insert.label}
                              </strong>
                            </Typography>
                          </div>
                          <Typography color="secondary">
                            -
                          </Typography>
                          <Typography color="secondary">
                            {insert.hint}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
            <div />
            <CardActions>
              <Button
                onClick={onCloseCreateUpdateCompensationDialog}
                variant="secondary"
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'cancel' })}
                </Typography>
              </Button>
              <Button
                isLoading={isFetchingCreateCompensation
                  || isFetchingUpdateCompensation}
                onClick={onSaveCompensation}
                variant="primary"
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'save' })}
                </Typography>
              </Button>
            </CardActions>
          </Card>
        </Dialog>
      )}

      {/* CREATE/UPDATE COMPENSATION. MOBILE */}
      {isMobile
        && (
          <Dialog
            onClose={onCloseCreateUpdateCompensationDialog}
            open={state.isCreateCompensation || !!state.editCompensationId}
          >
            {state.showErrorAfterSaveAlert && (
              <Card variant="error">
                <CardTitle>
                  <Typography color="error">
                    {formatMessage(
                      {
                        id: compensationErrorMessage
                          ? 'error'
                          : 'loading.error',
                      },
                      {
                        error: compensationErrorMessage,
                      }
                    )}
                  </Typography>
                </CardTitle>
              </Card>
            )}
            <Card variant="edit">
              <CardTitle>
                <Typography variant="title">
                  {formatMessage({
                    id: state.editCompensationId
                      ? 'compensation.update.title'
                      : 'compensation.create.title',
                  })}
                </Typography>
                <IconButton
                  disableHoverSpace
                  onClick={onCloseCreateUpdateCompensationDialog}
                >
                  <IconClose size={24} />
                </IconButton>
              </CardTitle>
              <CardContent disablePadding>
                <div className={classes.dialogContentMobile}>
                  <div className={classes.rowMobile}>
                    <Typography
                      color="secondary"
                      variant="subtitle"
                    >
                      {formatMessage({ id: 'compensation.SUM' })}
                    </Typography>
                    <div className={classes.dialogSum}>
                      <div className={classes.compensationSum}>
                        <TextField
                          disabled={isFetchingCreateCompensation
                            || isFetchingUpdateCompensation}
                          inputType="number"
                          onChange={({ target }) => setState({
                            ...state,
                            compensationSum: target.value,
                          })}
                          size="small"
                          value={state.compensationSum}
                          variant="outlined"
                        />
                      </div>
                      <Select
                        disabled={isFetchingCreateCompensation
                          || isFetchingUpdateCompensation}
                        disableUnderline
                        onChange={({ target }) => setState({
                          ...state,
                          compensationCurrency: target.value,
                        })}
                        value={state.compensationCurrency}
                      >
                        {currenciesList.map(currency => (
                          <MenuItem value={currency}>
                            <Typography>
                              {currency}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography
                        color="secondary"
                        variant="subtitle"
                      >
                        /
                      </Typography>
                      <Select
                        disabled={isFetchingCreateCompensation
                          || isFetchingUpdateCompensation}
                        disableUnderline
                        onChange={({ target }) => setState({
                          ...state,
                          compensationRateMeasure: target.value,
                        })}
                        value={state.compensationRateMeasure}
                      >
                        {rateMeasureTypesList.map(measureType => (
                          <MenuItem value={measureType}>
                            <Typography>
                              {formatMessage({
                                id: `rateMeasure.${measureType}`,
                              })}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className={classes.rowMobile}>
                    <Typography
                      color="secondary"
                      variant="subtitle"
                    >
                      {formatMessage({ id: 'compensation.TYPE' })}
                    </Typography>
                    <Select
                      disabled={isFetchingCreateCompensation
                        || isFetchingUpdateCompensation}
                      fullWidth
                      onChange={({ target }) => setState({
                        ...state,
                        compensationTypeId: target.value,
                      })}
                      size="small"
                      value={state.compensationTypeId}
                    >
                      {types.map((type: any) => (
                        <MenuItem value={type.id}>
                          <Typography>
                            {type.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={classes.rowMobile}>
                    <Typography
                      color="secondary"
                      variant="subtitle"
                    >
                      {formatMessage({ id: 'compensation.PERIODICITY' })}
                    </Typography>
                    <div className={classes.row}>
                      <div className={classes.dialogCell}>
                        <Typography>
                          {formatMessage({
                            id: 'compensation.PERIODICITY.from',
                          })}
                        </Typography>
                      </div>
                      <div className={classes.dialogPeriodicity}>
                        <div className={classes.selectPeriodicityMobile}>
                          <Select
                            disabled={isFetchingCreateCompensation
                              || isFetchingUpdateCompensation}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              compensationFromMonth: target.value,
                            })}
                            size="small"
                            value={state.compensationFromMonth}
                          >
                            <MenuItem value={null}>
                              <Typography>
                                {formatMessage({ id: 'NA' })}
                              </Typography>
                            </MenuItem>
                            {months.map((month) => (
                              <MenuItem value={month}>
                                <Typography>
                                  {formatMessage({ id: `month.${month}` })}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <div className={classes.selectYearMobile}>
                          <Select
                            disabled={isFetchingCreateCompensation
                              || isFetchingUpdateCompensation}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              compensationFromYear: target.value,
                            })}
                            size="small"
                            value={state.compensationFromYear}
                          >
                            <MenuItem value={null}>
                              <Typography>
                                {formatMessage({ id: 'NA' })}
                              </Typography>
                            </MenuItem>
                            {years.map(year => (
                              <MenuItem value={year}>
                                <Typography>
                                  {year}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.dialogCell}>
                        <Typography>
                          {formatMessage({
                            id: 'compensation.PERIODICITY.to',
                          })}
                        </Typography>
                      </div>
                      <div className={classes.dialogPeriodicity}>
                        <div className={classes.selectPeriodicityMobile}>
                          <Select
                            disabled={isFetchingCreateCompensation
                              || isFetchingUpdateCompensation}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              compensationToMonth: target.value,
                            })}
                            size="small"
                            value={state.compensationToMonth}
                          >
                            <MenuItem value={null}>
                              <Typography>
                                {formatMessage({ id: 'NA' })}
                              </Typography>
                            </MenuItem>
                            {months.map((month) => (
                              <MenuItem value={month}>
                                <Typography>
                                  {formatMessage({ id: `month.${month}` })}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <div className={classes.selectYearMobile}>
                          <Select
                            disabled={isFetchingCreateCompensation
                              || isFetchingUpdateCompensation}
                            fullWidth
                            onChange={({ target }) => setState({
                              ...state,
                              compensationToYear: target.value,
                            })}
                            size="small"
                            value={state.compensationToYear}
                          >
                            <MenuItem value={null}>
                              <Typography>
                                {formatMessage({ id: 'NA' })}
                              </Typography>
                            </MenuItem>
                            {years.map(year => (
                              <MenuItem value={year}>
                                <Typography>
                                  {year}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.rowMobile}>
                    <Typography
                      color="secondary"
                      variant="subtitle"
                    >
                      {formatMessage({ id: 'compensation.duration' })}
                    </Typography>
                    <Typography>
                      {getPeriodicityLabel({
                        fromMonth: state.compensationFromMonth,
                        fromYear: state.compensationFromYear,
                        toMonth: state.compensationToMonth,
                        toYear: state.compensationToYear,
                        withDuration: true,
                      })}
                    </Typography>
                  </div>
                  <div className={classes.rowMobile}>
                    <Typography
                      color="secondary"
                      variant="subtitle"
                    >
                      {formatMessage({ id: 'compensation.COMMENT' })}
                    </Typography>
                    <TextField
                      AdornmentEnd={
                        (state.validationErrors.includes(errorTypes
                          .INVALID_INSERT_COUNT) || state.validationErrors
                          .includes(errorTypes.INVALID_INSERT_NUMBER))
                        && (
                          <Tooltip
                            arrow
                            placement="top"
                            title={(
                              <>
                                {state.validationErrors.includes(errorTypes
                                  .INVALID_INSERT_NUMBER) && (
                                  <Typography
                                    color="inherit"
                                    variant="caption"
                                  >
                                    {formatMessage({
                                      id: `validationError.${errorTypes
                                        .INVALID_INSERT_NUMBER}`,
                                    })}
                                  </Typography>
                                )}
                                {state.validationErrors.includes(errorTypes
                                  .INVALID_INSERT_COUNT) && (
                                  <Typography
                                    color="inherit"
                                    variant="caption"
                                  >
                                    {formatMessage({
                                      id: `validationError.${errorTypes
                                        .INVALID_INSERT_COUNT}`,
                                    })}
                                  </Typography>
                                )}
                              </>
                            )}
                          >
                            <IconError
                              color={theme.colors.redDark}
                              size={20}
                            />
                          </Tooltip>
                        )
                      }
                      disabled={isFetchingCreateCompensation
                        || isFetchingUpdateCompensation}
                      fullWidth
                      inputRef={inputRef}
                      isError={state.validationErrors.includes(errorTypes
                        .INVALID_INSERT_COUNT) || state.validationErrors
                        .includes(errorTypes.INVALID_INSERT_NUMBER)}
                      onChange={({ target }) =>
                        setState((prevState: any) => ({
                          ...prevState,
                          compensationComment: target.value,
                        }))}
                      onSelect={() => setState((prevState: any) => ({
                        ...prevState,
                        compensationCommentCaretPosition: inputRef.current
                          .selectionStart,
                      }))}
                      multiline
                      placeholder={formatMessage({
                        id: 'compensation.COMMENT.placeholder',
                      })}
                      size="small"
                      value={state.compensationComment}
                      variant="standard"
                    />
                    <div className={classes.commentHintContainer}>
                      <Typography
                        color="secondary"
                        variant="caption"
                      >
                        {formatMessage({
                          id: 'compensation.COMMENT.hint',
                        })}
                      </Typography>
                      {dynamicInserts.map(insert => (
                        <div className={classes.dynamicInsertRowMobile}>
                          <div
                            className={classNames(
                              classes.dynamicInsertMobile,
                              insert.isAvailable && classes.clickable
                            )}
                            onClick={({ target }: any) =>
                              insert.isAvailable
                                ? handleDynamicInsert(target.innerText)
                                : undefined}
                            role="button"
                            tabIndex={0}
                          >
                            <Typography
                              color={insert.isAvailable
                                ? 'info'
                                : 'secondary'}
                              variant="caption"
                            >
                              <strong>
                                {insert.label}
                              </strong>
                            </Typography>
                          </div>
                          <div className={classes.dash}>
                            <Typography
                              color="secondary"
                              variant="caption"
                            >
                              -
                            </Typography>
                          </div>
                          <div className={classes.dynamicInsertHintMobile}>
                            <Typography
                              color="secondary"
                              variant="caption"
                            >
                              {insert.hint}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </CardContent>
              <div />
              <CardActions>
                {state.editCompensationId && (
                  <IconButton
                    disableHoverSpace
                    onClick={() => setState({
                      ...state,
                      deleteCompensationId: state.editCompensationId,
                    })}
                  >
                    <IconDelete size={20} />
                  </IconButton>
                )}
                <Button
                  onClick={onCloseCreateUpdateCompensationDialog}
                  variant="secondary"
                >
                  <Typography color="inherit">
                    {formatMessage({ id: 'cancel' })}
                  </Typography>
                </Button>
                <Button
                  isLoading={isFetchingCreateCompensation
                    || isFetchingUpdateCompensation}
                  onClick={onSaveCompensation}
                  variant="primary"
                >
                  <Typography color="inherit">
                    {formatMessage({ id: 'save' })}
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Dialog>
        )}

      {/* DELETE COMPENSATION DIALOG */}
      <Dialog
        onClose={onCloseDeleteCompensationDialog}
        open={!!state.deleteCompensationId}
      >
        {state.showErrorAfterSaveAlert && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage(
                  {
                    id: compensationErrorMessage ? 'error' : 'loading.error',
                  },
                  {
                    error: compensationErrorMessage,
                  }
                )}
              </Typography>
            </CardTitle>
          </Card>
        )}
        <Card>
          <CardTitle>
            <Typography variant="title">
              {formatMessage({ id: 'compensation.delete.title' })}
            </Typography>
            <IconButton onClick={onCloseDeleteCompensationDialog}>
              <IconClose size={24} />
            </IconButton>
          </CardTitle>
          <CardContent>
            <Typography color="secondary">
              {formatMessage({ id: 'compensation.delete.subtitle' })}
            </Typography>
          </CardContent>
          <div />
          <CardActions>
            <Button
              onClick={onCloseDeleteCompensationDialog}
              variant="secondary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'cancel' })}
              </Typography>
            </Button>
            <Button
              isLoading={isFetchingDeleteCompensation}
              onClick={() => dispatch(actionsCompensations
                .fetchDeleteCompensation({
                  compensationId: state.deleteCompensationId,
                  contractorId,
                }))}
              variant="primary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'delete' })}
              </Typography>
            </Button>
          </CardActions>
        </Card>
      </Dialog>

      {/* ALERTS */}
      <Snackbar
        autoHide
        onClose={() => setState({
          ...state,
          showSuccessAfterSaveAlert: false,
        })}
        open={state.showSuccessAfterSaveAlert}
      >
        <Card variant="success">
          <CardTitle>
            <Typography color="success">
              {formatMessage({
                id: `loading.success.${state.tab === tabs.DETAILS
                  ? 'contractor'
                  : 'compensations'}`,
              })}
            </Typography>
          </CardTitle>
        </Card>
      </Snackbar>
      <Snackbar
        open={state.showErrorAfterSaveAlert && state.tab === tabs.DETAILS}
      >
        <Card variant="error">
          <CardTitle>
            <Typography color="error">
              {formatMessage(
                {
                  id: contractorErrorMessage ? 'error' : 'loading.error',
                },
                {
                  error: contractorErrorMessage,
                }
              )}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={() => setState({
                ...state,
                showErrorAfterSaveAlert: false,
              })}
            >
              <IconClose size={24}/>
            </IconButton>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
}

export default Contractor;
