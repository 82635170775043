import {
  ERROR_CREATE_CONTRACT_TYPE,
  ERROR_DELETE_CONTRACT_TYPE,
  ERROR_RECEIVE_CONTRACT_TYPES,
  ERROR_UPDATE_CONTRACT_TYPE,
  RECEIVE_CONTRACT_TYPES,
  REQUEST_CREATE_CONTRACT_TYPE,
  REQUEST_DELETE_CONTRACT_TYPE,
  REQUEST_CONTRACT_TYPES,
  REQUEST_UPDATE_CONTRACT_TYPE,
  RESET_REDUCERS_DATA,
  SUCCESS_CREATE_CONTRACT_TYPE,
  SUCCESS_DELETE_CONTRACT_TYPE,
  SUCCESS_UPDATE_CONTRACT_TYPE,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  errorMessage: '',
  isFailed: false,
  isFailedCreate: false,
  isFailedDelete: false,
  isFailedUpdate: false,
  isFetching: false,
  isFetchingCreate: false,
  isFetchingDelete: false,
  isFetchingUpdate: false,
  isFinishedCreate: false,
  isFinishedDelete: false,
  isFinishedUpdate: false,
  list: [],
};

const convertContractTypes = (contractForms: any) => (contractForms || [])
  .map((type: any) => ({
    active: type.active,
    bankDetails: type.bankDetailsType,
    details: {
      includeInPolishPaymentRegistry: type.includeInPolishPaymentRegistry,
      isRoundPayrollSum: type.isRoundPayrollSum,
      shouldSendInvoiceInEmail: type.shouldSendInvoiceInEmail,
      showSumInUahInEmail: type.showSumInUahInEmail,
    },
    id: type.id,
    invoiceForm: type.invoiceForm,
    name: type.name,
    registryForm: type.paymentRegistryForm,
    taxTypeIdsCompensatedByCompany: type.taxTypeIdsCompensatedByCompany,
    taxTypeIdsPaidByCompany: type.taxTypeIdsPaidByCompany,
  }));

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_CONTRACT_TYPES: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }
    case RECEIVE_CONTRACT_TYPES: {
      const contractForms = action.payload;
      const list = convertContractTypes(contractForms);

      return {
        ...state,
        isFailed: false,
        isFetching: false,
        list,
      };
    }
    case ERROR_RECEIVE_CONTRACT_TYPES: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_CREATE_CONTRACT_TYPE: {
      return  {
        ...state,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: true,
        isFinishedCreate: false,
      };
    }
    case SUCCESS_CREATE_CONTRACT_TYPE: {
      const contractForms = action.payload;
      const list = convertContractTypes(contractForms);

      return {
        ...state,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: false,
        isFinishedCreate: true,
        list,
      };
    }
    case ERROR_CREATE_CONTRACT_TYPE: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedCreate: true,
        isFetchingCreate: false,
        isFinishedCreate: true,
      };
    }

    case REQUEST_DELETE_CONTRACT_TYPE: {
      return {
        ...state,
        errorMessage: '',
        isFailedDelete: false,
        isFetchingDelete: true,
        isFinishedDelete: false,
      };
    }
    case SUCCESS_DELETE_CONTRACT_TYPE: {
      const contractForms = action.payload;
      const list = convertContractTypes(contractForms);

      return {
        ...state,
        errorMessage: '',
        isFailedDelete: false,
        isFetchingDelete: false,
        isFinishedDelete: true,
        list,
      };
    }
    case ERROR_DELETE_CONTRACT_TYPE: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedDelete: true,
        isFetchingDelete: false,
        isFinishedDelete: true,
      };
    }

    case REQUEST_UPDATE_CONTRACT_TYPE: {
      return {
        ...state,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: true,
        isFinishedUpdate: false,
      };
    }
    case SUCCESS_UPDATE_CONTRACT_TYPE: {
      const contractForms = action.payload;
      const list = convertContractTypes(contractForms);

      return {
        ...state,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
        list,
      };
    }
    case ERROR_UPDATE_CONTRACT_TYPE: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedUpdate: true,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
